import React from "react";
import Header from "./Header/Header"
import { Link } from "gatsby"
import "../styles/global.css"
import {Helmet} from "react-helmet";

const metaDefaults = {
  title: 'The Starless Land - Fantasy Webcomic',
  description: 'Welcome to The Starless Land, a fantasy/adventure webcomic! In a world defined by huge imbalances of magic, Shimurli must unravel the mystery of her identity and a riddle from an ancient sage to stay ahead of enemies who want to use her power to throw the world into chaos.',
  image: process.env.GATSBY_AWS_S3_BASE_URL + '/pages/Covers/Cover_0.jpg'
}

export default ({ metaOptions, children }) => {
  const [expanded, setExpanded] = React.useState(false);
  let meta;
  if (metaOptions) {
    meta = Object.assign({}, metaDefaults, metaOptions)
  } else {
    meta = metaDefaults;
  }

  return (
    <div>
      <Helmet>
        <link rel="shortcut icon" href="/StarIcon.png" />
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        {meta.image ? (<meta property="og:image" content={meta.image} />) : null}
      </Helmet>
      <Header/>
      <p className="update-schedule">Updates Tuesday!</p>
      <div className="main">
        <nav className="navigation" onClick={() => setExpanded(!expanded)}>
          <ul className={expanded ? "expanded" : ""}>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/comic/">Comic</Link></li>
            <li><Link to="/archive/">Archive</Link></li>
            <li><Link to="/cast/">Cast</Link></li>
            <li><Link to="/world/">World</Link></li>
            <li><Link to="/about/">About</Link></li>
            <li><Link to="/blog/">Blog</Link></li>
            <li><Link to="/gallery/">Gallery</Link></li>
          </ul>
        </nav>
        <div className="wavesImg">
          <div className="centeredImg"/>
        </div>
        {children}
      </div>
      <footer>©2020 by Larissa S</footer>
    </div>
  )
}