import React, { Component } from 'react';
import headerLayer1 from './Parrallax-logo-sky-wide.png';
import headerLayer2 from './Parrallax-logo-ice-wide.png';
import headerLayer3 from './Parrallax-logo-text-wide.png';
import smallLogo from './IcebergHeader-small.jpg';
import mediumLogo from './IcebergHeader-medium.jpg';
import { Parallax } from 'react-parallax';
import "./Header.css";

function renderLogoImage(withParrallax, hide) {
  return withParrallax ? (
    <div className="header-logo parralax-height" style={hide ? {opacity: 0} : null}>
      <img alt="The Starless Land header logo" src={headerLayer1} className="parralax-height"/>
      <div className="parralax-top">
        <Parallax
            strength={100}
            bgImage={headerLayer2}>
          <img src={headerLayer3} alt="" className="parralax-height"/>
        </Parallax>
      </div>
    </div>
  ) : (
    <picture className="header-logo-responsive">
      <source media="(max-width: 650px)" srcSet={smallLogo}/>
      <img src={mediumLogo} alt="The Starless Land header logo"/>
    </picture>
  )
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { usingParrallax: this.usingParrallax(), hide: true};
    this.handleResize = this.handleResize.bind(this);
  }

  usingParrallax() {
    if (typeof (window) !== "undefined") {
      return (window.innerWidth > 1050);
    }
    return false;
  }

  handleResize() {
    const usingParrallax = this.usingParrallax();
    if (this.state.usingParrallax !== usingParrallax) {
      this.setState({ usingParrallax });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);

    window.setTimeout(() => {
      this.setState({ hide: false });
    }, 5);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const {hide} = this.state;
    return (
        <div className="header">
          {renderLogoImage(this.state.usingParrallax, hide)}
        </div>
    );
  }
}

export default Header;
